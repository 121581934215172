import { reactive } from "vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { useColumns } from "../useColumns";

type Key = ColumnProps["key"];
export const useFAQTable = (
  searchUser: (current: number, pageSize: number) => void
): any => {
  const columns = useColumns();

  const userColumns = columns;

  const userRowSelectedState = reactive<{
    selectedRowKeys: Key[];
    selectedRows: any[];
  }>({
    selectedRowKeys: [],
    selectedRows: [],
  });

  const onSelectChange = (selectedRowKeys: Key[], selectedRows: any[]) => {
    console.log("selectedRows changed: ", selectedRows);
    userRowSelectedState.selectedRowKeys = selectedRowKeys;
    userRowSelectedState.selectedRows = selectedRows;
  };

  const userPagination = {
    current: 1,
    pageSize: 20,
    total: 0,
  };

  // user 和 rule 的表格
  const userAndRuleColumns = [
    ...columns,
    {
      title: "Operation",
      dataIndex: "operation",
      slots: {
        customRender: "operation",
      },
    },
  ];

  const userAndRolePagination = {
    // current: 1,
    // pageSize: 20,
    total: 10,
    defaultPageSize: 20,
    showQuickJumper: true,
    showSizeChanger: true,
    showTotal: (total: number) => `Total ${total} items`,
    // onChange: (current: number, pageSize: number) => {
    //   // userAndRolePagination.current = current;
    //   // getUserAndRule(current, pageSize);
    // },
    // onShowSizeChange: (current: number, pageSize: number) => {
    //   userAndRolePagination.pageSize = pageSize;
    //   // getUserAndRule(current, pageSize);
    // },
  };

  return {
    userColumns,
    userRowSelectedState,
    onSelectChange,
    userPagination,
    userAndRuleColumns,
    userAndRolePagination,
  };
};
