import { computed, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { UndefStr } from "../types";
import { getBuSubregion } from "@/API/salesReporting/claimCheckingReport";
import { getAreasDate } from "@/API/checking/claimDelayRuleSetup";
import { getentityAll, findEntityByids } from "@/API/approve";
import { getAllDealerType } from "@/API/faq";

export const useSearch = () => {
  const { state } = useStore();

  // login name
  const loginName = reactive({
    label: "Login Name",
    names: [],
    batchQueryTitle: "Login Name Batch Enquiry",
    batchQueryListTitle: "Login Name List",
  });

  const changeLoginName = (vals: any) => {
    loginName.names = vals;
  };

  // legal entity
  const legalEntity = ref<UndefStr>(undefined);
  const legalEntityList = ref<any[]>([]);
  getentityAll().then((res) => {
    legalEntityList.value = res;
  });

  // user group
  const userGroup = ref<UndefStr>(undefined);
  const userGroupList = ref<any[]>([]);
  const changeEntity = () => {
    userGroup.value = undefined;
    userGroupList.value = [];
    if (legalEntity.value) {
      findEntityByids(legalEntity.value).then((res) => {
        userGroupList.value = res;
      });
    }
  };

  // bu
  const bu = ref<UndefStr>(undefined);
  const buList = computed(() =>
    state.user.buList.filter((bu: any) => {
      return bu.parentId === null;
    })
  );

  // region
  const region = ref<UndefStr>(undefined);
  const regionList = ref<any>([]);
  const getRegion = () => {
    regionList.value = [];
    if (!bu.value) return;
    getBuSubregion({ buId: bu.value }).then((res) => {
      region.value = undefined;
      const sortArr: any[] = [[], [], [], [], []];
      const maps = {
        n: 0,
        e: 1,
        s: 3,
        w: 2,
      };
      res.forEach((region: any) => {
        const firstLetter = (region.regionEn[0] as string).toLocaleLowerCase();
        if (firstLetter in maps) {
          sortArr[maps[firstLetter]].push(region);
        } else {
          sortArr[4].push(region);
        }
      });
      regionList.value = sortArr.flat();
    });
  };
  const disabledRegion = computed(() => {
    const userGroupName = userGroupList.value?.filter((group: any) => {
      return group.id === userGroup.value;
    })[0]?.entityNameEn;
    if (
      legalEntity.value &&
      (["10007"].includes(legalEntity.value) || userGroupName === "Region") &&
      bu.value
    ) {
      return false;
    }
    return true;
  });
  watch(disabledRegion, (newVal) => {
    if (newVal) {
      region.value = undefined;
    }
  });

  // dealer group
  const dealerGroup = ref<UndefStr>(undefined);
  const dealerGroupList = ref<any>([]);
  findEntityByids(10008).then((res) => {
    dealerGroupList.value = res;
  });
  const disabledDealerGroup = computed(() => {
    if (legalEntity.value && ["10008", "10007"].includes(legalEntity.value)) {
      return false;
    }
    return true;
  });
  watch(disabledDealerGroup, (newVal) => {
    if (newVal) {
      dealerGroup.value = undefined;
    }
  });

  // province
  const province = ref<UndefStr>(undefined);
  // city
  const city = ref<UndefStr>(undefined);
  const provinceList = ref<any>([]);
  const cityList = ref<any>([]);
  let provinceCityMap: any;
  const disabledProvince = computed(() => {
    if (legalEntity.value && legalEntity.value === "10007") {
      return false;
    }
    return true;
  });
  watch(disabledProvince, (newVal) => {
    if (newVal) {
      province.value = undefined;
    }
  });
  const disabledCity = computed(() => {
    if (province.value) return false;
    return true;
  });
  watch(disabledCity, (newVal) => {
    if (newVal) {
      city.value = undefined;
    }
  });
  const getProvinceList = () => {
    getAreasDate().then((res: any) => {
      const provinces = res;
      provinceList.value = provinces;
      provinceCityMap = res.reduce((maps: any, pro: any) => {
        return { ...maps, [pro.provinceEn]: pro.cityList };
      }, {});
    });
  };
  getProvinceList();
  const changeProvince = () => {
    if (province.value) {
      cityList.value = provinceCityMap[province.value];
    } else {
      cityList.value = [];
    }
  };
  // dealer code
  const dealerCode = reactive({
    label: "Dealer Code",
    dealerCodes: [],
    batchQueryTitle: "Dealer Code Batch Enquiry",
    batchQueryListTitle: "Dealer Code List",
  });

  const changeDealerCode = (vals: any) => {
    dealerCode.dealerCodes = vals;
  };

  // swt code
  const swtCode = reactive({
    label: "SWT Code",
    swtCodes: [],
    batchQueryTitle: "SWT Code Batch Enquiry",
    batchQueryListTitle: "SWT Code List",
  });

  const changeSwtCode = (vals: any) => {
    swtCode.swtCodes = vals;
  };
  // dealer name
  const dealerName = ref<UndefStr>(undefined);
  // dealer type
  const dealerType = ref<UndefStr>(undefined);
  const dealerTypeList = ref<any[]>([]);
  getAllDealerType().then((res: any) => {
    dealerTypeList.value = res;
  });
  const disabledDealerType = computed(() => {
    if (legalEntity.value && legalEntity.value === "10007") {
      return false;
    }
    return true;
  });
  watch(disabledDealerType, (newVal) => {
    if (newVal) {
      dealerType.value = undefined;
    }
  });

  return {
    loginName,
    changeLoginName,
    legalEntity,
    legalEntityList,
    changeEntity,
    userGroup,
    userGroupList,
    bu,
    buList,
    region,
    regionList,
    getRegion,
    disabledRegion,
    dealerGroup,
    dealerGroupList,
    disabledDealerGroup,
    province,
    provinceList,
    disabledProvince,
    changeProvince,
    city,
    cityList,
    disabledCity,
    dealerCode,
    changeDealerCode,
    swtCode,
    changeSwtCode,
    dealerName,
    dealerType,
    dealerTypeList,
    disabledDealerType,
  };
};
