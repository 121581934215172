import { fetchGet, fetchPost, fetchPostPic, fetchDeletePic } from '../index';

//生成setup information review列表
export const getSetupInformationReview = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/delay/rule/list', params);
};
//Add
export const addSetupLst = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/delay/rule/add', params);
};
//Edit
export const editSetupLst = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/delay/rule/edit', params);
};
//删除
export const deleteSetupLst = (params = { id: '' }): Promise<any> => {
    return fetchDeletePic(`/claimapi/delay/rule/${params.id}`, params);
};
//view
export const setupLstView = (params = { id: '' }): Promise<any> => {
    return fetchGet(`/claimapi/delay/rule/${params.id}`, params);
};
// 获取bu下拉框数据
export const getBuList = (): Promise<any> => {
    // return fetchGet('/claimapi/doc/dic/getBuList')
    return fetchGet('/mdapi/bu');
};
export const getBuListNew = (): Promise<any> => {
    return fetchGet('/usapi/noAuth/bu/list');
};
// 获取省份城市 限牌城市
export const getAreasDate = () => {
    return fetchGet('/dapi/noAuth/dealer/provinceCity');
    // return fetchGet('/mdapi/areas');
};
//生成region列表
export const getRegionLst = (params = { bu: '' }): Promise<any> => {
    return fetchPost(`/claimapi/common/regionQuery?bu=${params.bu}`, params);
};
