
import {
  computed,
  createVNode,
  defineComponent,
  onMounted,
  ref,
  toRaw,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { message, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import MultiInput from "../components/MultiInput.vue";
import { useSearch } from "./useSearch";
import { useFAQTable } from "./useFAQTable";
import {
  getConfigrateUser,
  getSavedUserAndRole,
  deleteUser,
  deleteRule,
  saveUserAndRule,
  clearUserAndRule,
} from "@/API/faq";

const getUniqueKey = () => {
  return Date.now().toString();
};

export default defineComponent({
  components: {
    MultiInput,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const faqId = route.params.faqId as string;
    const spaceSize = 6;

    const {
      loginName,
      changeLoginName,
      legalEntity,
      legalEntityList,
      changeEntity,
      userGroup,
      userGroupList,
      bu,
      buList,
      region,
      regionList,
      getRegion,
      disabledRegion,
      dealerGroup,
      dealerGroupList,
      disabledDealerGroup,
      province,
      provinceList,
      disabledProvince,
      changeProvince,
      city,
      cityList,
      disabledCity,
      dealerCode,
      changeDealerCode,
      swtCode,
      changeSwtCode,
      dealerName,
      dealerType,
      dealerTypeList,
      disabledDealerType,
    } = useSearch();

    const genParams = () => {
      const lgName = loginName.names.length === 1 ? loginName.names[0] : "";
      const swt = swtCode.swtCodes.length === 1 ? swtCode.swtCodes[0] : "";
      const dcode =
        dealerCode.dealerCodes.length === 1 ? dealerCode.dealerCodes[0] : "";
      return {
        bu: bu.value,
        city: city.value,
        dealerCode: dcode,
        dealerCodeList: dcode !== "" ? [] : dealerCode.dealerCodes,
        dealerGroup: dealerGroup.value,
        dealerName: dealerName.value,
        dealerType: dealerType.value,
        legalEntity: legalEntity.value,
        loginName: lgName,
        loginNameList: lgName !== "" ? [] : loginName.names,
        province: province.value,
        region: region.value,
        swtCode: swt,
        swtCodeList: swt !== "" ? [] : swtCode.swtCodes,
        userGroup: userGroup.value,
      };
    };

    // 以下逻辑为添加 user 或者 rule 相关
    const tableKey = ref<string>(getUniqueKey());

    // 上方 user 表格数据
    const userDataSource = ref<any[]>([]);
    // 已保存的 user/role 数据, 一次性从后端获取 前端分页
    const userAndRuleDataSaved = ref<any[]>([]);
    // 已保存的数据的 id
    const savedIdList = computed(() => {
      const list = userAndRuleDataSaved.value
        .map((item: any) => {
          return item.id;
        })
        .filter(Boolean);
      return list;
    });
    // 进入页面后新操作的新增的数据;
    const newAddedUserAndRole = ref<any[]>([]);
    // 下方 被添加的 user/rule 的表格数据, 包括从后端获取以及新增的
    const userAndRuleDataAll = computed(() => {
      return [...userAndRuleDataSaved.value, ...newAddedUserAndRole.value];
    });
    const userAndRuleDataAllTotal = computed(() => {
      return userAndRuleDataAll.value.length;
    });
    // 所有已添加的 user/rule 的id
    const allIdList = computed(() => {
      const list = userAndRuleDataAll.value
        .map((item: any) => {
          return item.userId;
        })
        .filter(Boolean);
      return list;
    });

    const searchUser = (current: number, pageSize: number) => {
      const params = genParams();
      getConfigrateUser({ ...params, current, size: pageSize }).then(
        (res: any) => {
          const content = res.content;
          userDataSource.value = content.map((user: any) => {
            return { ...user, type: "user" };
          });
          userPagination.total = res.totalElements;
        }
      );
    };

    const handleSearch = () => {
      userPagination.current = 1;
      searchUser(1, userPagination.pageSize);
    };

    const getUserAndRule = () => {
      const params = {
        faqId,
      };
      getSavedUserAndRole(params).then((res: any) => {
        userAndRuleDataSaved.value = res;
      });
    };

    const {
      userColumns,
      userRowSelectedState,
      onSelectChange,
      userPagination,
      userAndRuleColumns,
      userAndRolePagination,
    } = useFAQTable(searchUser);
    userAndRolePagination.total = userAndRuleDataAllTotal.value;

    // 处理添加user 和 role 的逻辑, 需要维护一个新增数组
    const checkUserSelectable = (record: any) => {
      if (allIdList.value.includes(record.userId)) {
        return true;
      }
      return false;
    };

    const handleAddUser = () => {
      if (userRowSelectedState.selectedRows.length === 0) {
        message.warning("Please select user!");
        return;
      }
      newAddedUserAndRole.value = [
        ...newAddedUserAndRole.value,
        ...userRowSelectedState.selectedRows,
      ];
      // 改变key值刷新表格1组件
      tableKey.value = getUniqueKey();
      // 清空选择的相关内容
      userRowSelectedState.selectedRowKeys = [];
      userRowSelectedState.selectedRows = [];
    };

    const isAddRuleCheckFailed = () => {
      if (
        loginName.names.length === 0 &&
        legalEntity.value === undefined &&
        userGroup.value === undefined &&
        bu.value === undefined &&
        region.value === undefined &&
        dealerGroup.value === undefined &&
        province.value === undefined &&
        city.value === undefined &&
        dealerCode.dealerCodes.length === 0 &&
        swtCode.swtCodes.length === 0 &&
        dealerName.value === undefined &&
        dealerType.value === undefined
      ) {
        return true;
      }
      return false;
    };

    const genConditionToRule = () => {
      // 将选择的筛选条件生成规则
      const buName = buList.value.filter((b: any) => {
        return b.id === bu.value;
      })[0]?.nameEn;
      const dealerGroupName = dealerGroupList.value?.filter((group: any) => {
        return group.entityCode === dealerGroup.value;
      })[0]?.entityNameEn;
      const dealerTypeName = dealerTypeList.value?.filter((type: any) => {
        return type.dealerTypeId === dealerType.value;
      })[0]?.dealerTypeEn;
      const legalEntityName = legalEntityList.value?.filter((legal: any) => {
        return legal.id === legalEntity.value;
      })[0]?.entityNameEn;
      const userGroupName = userGroupList.value?.filter((usg: any) => {
        return usg.id === userGroup.value || usg.entityCode === userGroup.value;
      })[0]?.entityNameEn;
      const regionName = regionList.value?.filter((reg: any) => {
        return reg.id === region.value;
      })[0]?.regionEn;

      const lgName = loginName.names.length === 1 ? loginName.names[0] : "";
      const swt = swtCode.swtCodes.length === 1 ? swtCode.swtCodes[0] : "";
      const dcode =
        dealerCode.dealerCodes.length === 1 ? dealerCode.dealerCodes[0] : "";
      return {
        loginName: lgName || "",
        loginNameList: lgName !== "" ? [] : toRaw(loginName.names),
        legalEntity: legalEntity.value || "",
        legalEntityName: legalEntityName || "",
        userGroup: userGroup.value || "",
        userGroupName: userGroupName || "",
        bu: bu.value || "",
        buName: buName || "",
        region: region.value || "",
        regionName: regionName || "",
        dealerGroup: dealerGroup.value || "",
        dealerGroupName: dealerGroupName || "",
        province: province.value || "",
        city: city.value || "",
        dealerCode: dcode || "",
        dealerCodeList: dcode !== "" ? [] : toRaw(dealerCode.dealerCodes),
        swtCode: swt || "",
        swtCodeList: swt !== "" ? [] : toRaw(swtCode.swtCodes),
        dealerName: dealerName.value || "",
        dealerType: dealerType.value || "",
        dealerTypeName: dealerTypeName || "",
      };
    };

    const hasRuleAdded = (generatedRule: any) => {
      const allRules = userAndRuleDataAll.value
        .filter((item: any) => {
          return item.userId === null;
        })
        .map((item: any) => {
          return toRaw(item);
        });
      const totalKeys = Object.keys(generatedRule).length;
      const listKey = ["loginNameList", "dealerCodeList", "swtCodeList"];
      for (const rule of allRules) {
        let countKeys = 0;
        for (const key of Object.keys(generatedRule)) {
          if (listKey.includes(key)) {
            if (
              rule[key].sort().join(",") === generatedRule[key].sort().join(",")
            ) {
              countKeys += 1;
            }
          } else {
            if (
              rule[key] === generatedRule[key] ||
              (rule[key] === null && generatedRule[key] === "")
            ) {
              countKeys += 1;
            }
          }
        }
        if (countKeys === totalKeys) {
          return true;
        }
      }
      return false;
    };

    const handleAddRule = () => {
      if (isAddRuleCheckFailed()) {
        message.warning("Please select search rules!");
        return;
      }
      const generatedRule = genConditionToRule();
      if (hasRuleAdded(generatedRule)) {
        message.warning("This rule has been added!");
        return;
      }
      const valObj = generatedRule;
      const ruleObj = {
        type: "rule",
        ruleId: getUniqueKey(), // ruleId??
        userId: null,
        ...valObj,
      };
      newAddedUserAndRole.value.push(ruleObj);
    };

    const deleteUserAndRule = (id: string, userId: string | null) => {
      Modal.confirm({
        title: "Are you sure you want to delete this user/rule?",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "Confirm",
        cancelText: "Cancel",
        onOk() {
          // 判断是在 userAndRuleDataSaved, 还是 newAddedUserAndRole
          if (savedIdList.value.includes(id)) {
            let deleteUserAndRule;
            if (userId !== null) {
              deleteUserAndRule = deleteUser;
            } else {
              deleteUserAndRule = deleteRule;
            }
            deleteUserAndRule(id).then(() => {
              getUserAndRule();
            });
          } else {
            const deletedIndexInNews = newAddedUserAndRole.value.findIndex(
              (item: any) => {
                const currentId = item.userId || item.ruleId;
                return currentId === userId;
              }
            );
            newAddedUserAndRole.value.splice(deletedIndexInNews, 1);
          }
          // 改变key值刷新表格1组件
          if (userId !== null) {
            tableKey.value = getUniqueKey();
          }
        },
      });
    };

    const handleSave = () => {
      const params = {
        faqConfigurateList: newAddedUserAndRole.value,
        faqId,
      };
      saveUserAndRule(params).then(() => {
        message.success("Successfully Saved");
        router.push({
          name: "FAQ Edit",
          params: {
            faqId,
          },
        });
      });
    };

    const handleReset = () => {
      loginName.names = [];
      legalEntity.value = undefined;
      userGroup.value = undefined;
      bu.value = undefined;
      region.value = undefined;
      dealerGroup.value = undefined;
      province.value = undefined;
      city.value = undefined;
      dealerCode.dealerCodes = [];
      swtCode.swtCodes = [];
      dealerName.value = undefined;
      dealerType.value = undefined;
    };

    const handleClear = () => {
      clearUserAndRule(faqId);
      userAndRuleDataSaved.value = [];
      newAddedUserAndRole.value = [];
      tableKey.value = getUniqueKey();
    };

    onMounted(() => {
      getUserAndRule();
    });

    return {
      faqId,
      tableKey,
      spaceSize,
      //
      loginName,
      changeLoginName,
      legalEntity,
      legalEntityList,
      changeEntity,
      userGroup,
      userGroupList,
      bu,
      buList,
      region,
      regionList,
      getRegion,
      disabledRegion,
      dealerGroup,
      dealerGroupList,
      disabledDealerGroup,
      province,
      provinceList,
      disabledProvince,
      changeProvince,
      city,
      cityList,
      disabledCity,
      dealerCode,
      changeDealerCode,
      swtCode,
      changeSwtCode,
      dealerName,
      dealerType,
      dealerTypeList,
      disabledDealerType,
      //
      userColumns,
      userRowSelectedState,
      onSelectChange,
      userPagination,
      userAndRuleColumns,
      userAndRolePagination,
      userDataSource,
      userAndRuleDataAll,
      searchUser,
      handleSearch,
      handleAddUser,
      handleAddRule,
      checkUserSelectable,
      deleteUserAndRule,
      handleSave,
      handleReset,
      handleClear,
    };
  },
});
