export const useColumns = () => {
  return [
    // {
    //   title: "Id",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Login Name",
      // dataIndex: "loginName",
      key: "loginName",
      slots: {
        customRender: "loginName",
      },
    },
    {
      title: "User Group",
      dataIndex: "userGroupName",
      key: "userGroupName",
      ellipsis: true,
    },
    {
      title: "BU",
      dataIndex: "buName",
      key: "buName",
      ellipsis: true,
    },
    {
      title: "Region",
      dataIndex: "regionName",
      key: "regionName",
      ellipsis: true,
    },
    {
      title: "Dealer Group",
      dataIndex: "dealerGroupName",
      key: "dealerGroupName",
    },
    {
      title: "Province",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Legal Entity",
      dataIndex: "legalEntityName",
      key: "legalEntityName",
    },
    {
      title: "Dealer Code",
      // dataIndex: "dealerCode",
      key: "dealerCode",
      slots: {
        customRender: "dealerCode",
      },
    },
    {
      title: "SWT Code",
      // dataIndex: "swtCode",
      key: "swtCode",
      slots: {
        customRender: "swtCode",
      },
    },
    {
      title: "Dealer Name",
      dataIndex: "dealerName",
      key: "dealerName",
      ellipsis: true,
    },
    {
      title: "Dealer Type",
      dataIndex: "dealerTypeName",
      key: "dealerTypeName",
      ellipsis: true,
    },
  ];
};
